import React from 'react';
import { Pane, Heading, Card, Paragraph, majorScale, Text } from 'evergreen-ui';
import { Icon } from 'evergreen-ui';

// Replace this with the actual icons you want to use
const beliefIcons = {
  "The Bible": "book",
  "God": "globe",
  // ... other icons for each belief
};

const beliefs = [
  {
    title: "THE UNITY OF THE GODHEAD AND THE TRINITY OF THE PERSONS THEREIN",
    description: "The Church believes in the Holy Trinity of the Father, the Son, and the Holy Spirit; the father being the Supreme God, maker of heaven and earth, and the creator of all creatures. Therefore Christian teaching of the Trinity states that God exists eternally as three persons who, while distinct, are equally and fully God. Useful Scriptures: Matt 28:19, 2 Cor 13:14, 1 John 5:7"
  },
  {
    title: "THE UTTER DEPRAVITY OF HUMAN NATURE, THE NECESSITY FOR REPENTANCE AND REGENERATION AND THE ETERNAL DOOM OF THE FINALLY IMPENITENT",
    description: "Every part of human nature is deeply damaged by the Fall. We are born with a powerful bias toward sin. God commands us to confess our sin and turn from it to him as our Saviour and Lord. We require the new spiritual birth which the Holy Spirit gives. Any who refuse God’s command and offer of life will suffer everlasting separation from him in hell. Useful Scriptures: Rom 3:23, Eph 2, Acts 20:21, 2 Cor 5:17, Rev 20:11-15."
  },
  {
    title: "THE VIRGIN BIRTH, SINLESS LIFE, ATONING DEATH, TRIUMPHANT RESURRECTION, ASCENSION AND ABIDING INTERCESSION OF OUR LORD JESUS CHRIST; HIS SECOND COMING AND MILLENNIAL REIGN UPON EARTH",
    description: "God’s eternal Son was born to Mary, a virgin, from whom he received his humanity. Fully human, he was nonetheless without sin, having been conceived by the Holy Spirit. Having lived with perfect holiness, he bore in his death the penalty of all others’ sin. After God the Father raised him from death he returned in triumph to heaven where he represents his people. He will come again to rule over the earth for a thousand years. Useful Scriptures: Matt 1:18-25, Hebrews 2:9, 1 Pet 2:21-24, Acts 2: 29-32."
  },
  {
    title: "JUSTIFICATION AND SANCTIFICATION OF THE BELIEVERS THROUGH THE-FINISHED WORK OF CHRIST",
    description: "Through his life and death Jesus provided fully for our salvation. When we trust in him as our Saviour we are completely forgiven and declared to be in right standing with God. We are now set apart for God. By the Holy Spirit’s power we can grow in the Christ-likeness God requires of us. Useful Scriptures: Rom 3:21-24, 2 Cor 3:17-18, Eph 4:23-24."
  },
  {
    title: "THE BAPTISM OF THE HOLY SPIRIT FOR BELIEVERS WITH SIGNS FOLLOWING",
    description: "The baptism of the Holy Spirit is followed by clear evidences of the Spirit’s work such as speaking in tongues, seeing vision, dreaming, and prophesying. This is the reason why Pentecostal churches like CAC are called “Spiritual” churches. Useful Scriptures: Matt 3:11, Mark 16:17, Acts 1:8, Acts 2, Acts 10:44-48."
  },
  {
    title: "THE NINE GIFTS OF THE HOLY SPIRIT FOR THE EDIFICATION, EXHORTATION AND COMFORT OF THE CHURCH WHICH IS THE BODY OF CHRIST",
    description: "The nine gifts of the Holy Spirit are found in 1 Cor 12: 8-11. Christians who possess these gifts have supernatural abilities. Their use helps to build up, encourage and strengthen the church, a people in living union with Christ. Useful Scriptures: 1 Cor 12:4-28, 1 Cor 13:9-12, Gal 5:22-23."
  },
  {
    title: "THE SACRAMENTS OF BAPTISM BY IMMERSION AND OF THE LORD’S SUPPER",
    description: "Water baptism is the first step after being admitted to the Church. Jesus commanded that new believers be plunged in water to symbolize our sharing in the benefits of his saving work. His death and resurrection are the permanent basis of Christian life. Therefore he also ordained that the church should meet to remember him be eating bread and drinking wine as symbols of his body and blood. The lower age limit for baptism is 12 years and only an ordained pastor of the church can perform the baptism. To partake in the Lord’s super, a member must be 12 years old and above and not practice polygamy. If the husband is a polygamist, the first wife qualifies to partake. Useful Scriptures: Matt 3:16-17, Matt 28:19, 1 Cor 11:23-26, Rom 6:3-14."
  },
  {
    title: "THE DIVINE INSPIRATION AND AUTHORITY OF THE HOLY SCRIPTURES",
    description: "The Church believes that both the Old and New Testaments, comprising the Holy scriptures, were written by individuals through guidance by the Holy Spirit. The Scripture contains what God wants Christians to believe about God, salvation, life, etc. Useful Scriptures: John 1:1-4, 2 Tim 3:16-17, 2 Pet 1:20-21."
  },
  {
    title: "CHURCH GOVERNMENT BY APOSTLES, PROPHETS, EVANGELISTS, PASTORS, CHURCH TEACHERS AND ELDERS/DEACONS",
    description: "The government of the Church is based on the Apostolic order. Church leadership is provided by men with varying God-given ministries. They complement each other and together express Christ’s government of his church. Useful Scriptures: Eph 4:11-13, 1 Tim 3:1-13, Titus 1:5-11."
  },
  {
    title: "THE POSSIBILITY OF FALLING FROM GRACE",
    description: "The Church believes that a member could fall from God’s grace if he or she backslides and abandons the faith (Heb 6:4-6). God is faithful and gracious to his people, earnestly desiring and providing for our salvation. It is possible, however, for a Christian to forfeit salvation by a wilful failure to continue in faith and obedience. Useful Scriptures: 1 Cor 10:12, 1 Tim 1:19, Heb 3:12, Heb 6:4-6."
  },
  {
    title: "The obligatory nature of Tithes and Offerings",
    description: "The payment of these offerings is a cardinal belief of the Church, and it is obligatory for both workers and members. The belief is based on Jacob’s vow at Bethel. Obedience to the belief carries with it many blessings. Proceeds from the offerings are used as stipends for church workers and to advance the Gospel of our Lord Jesus Christ. Useful Scriptures: Gen 28:20-21, Malachi 3:10-12."
  },
  {
    title: "Divine Healing through obedience to the command of our Lord Jesus Christ and Faith in His name and merits of His blood for all sickness, disease and infirmities",
    description: "This belief was developed prior to 1930 when the founding fathers had association with Faith Tabernacle of Philadelphia. This faith together with the belief in salvation from sin, and reliance on God for all needs form the three pillars on which CAC faith rests. Members therefore rely on God for healing. Useful Scriptures: James 5:14-18; 2 Kings 1:2-4."
  },
  {
    title: "Faith in God, the Jehovah Jireh to supply all financial needs",
    description: "This belief is hinged on Abraham’s obedience to sacrifice Isaac to the Lord. Once man obeys God, He will provide all his needs since He is all sufficient. Useful Scriptures: Gen 22:12-14, Matt 6:2; 5:34, Phil 4:19."
  }
];


// BeliefCard component
const BeliefCard = ({ title, description }) => (
  <Card
    elevation={1}
    hoverElevation={3}
    width={280} // Adjust the width as needed
    margin={majorScale(2)}
    padding={majorScale(3)}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="flex-start" // Align items to start from top
    background="white"
    borderRadius={5} // A bit more rounded corners than the default
  >
    <Icon icon="cross" size={30} color="#FFD700" marginBottom={majorScale(2)} />
    <Heading size={600} marginBottom={majorScale(2)} textAlign="center">{title}</Heading>
    <Paragraph size={400} textAlign="center">{description}</Paragraph>
  </Card>
);

// OurBeliefsPage component
const OurBeliefsPage = () => (
  <Pane display="flex" flexDirection="column" alignItems="center" padding={majorScale(4)}>
    <Heading size={900} marginBottom={majorScale(5)} textAlign="center">Our Beliefs</Heading>
    <Pane display="flex" flexWrap="wrap" justifyContent="center">
      {beliefs.map((belief, index) => (
        <BeliefCard key={index} {...belief} />
      ))}
    </Pane>
  </Pane>
);


export default OurBeliefsPage;
