import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Pane, Popover, Position, Button, Heading, majorScale } from 'evergreen-ui';
import logobanner from '../Assets/images/logo-banner-transp.png';

function Navbar() {
  const [aboutHover, setAboutHover] = useState(false);

  const handleMouseEnter = (setter) => () => setter(true);
  const handleMouseLeave = (setter) => () => setter(false);

  const navbarItems = [
    {
      title: 'ABOUT US',
      hover: aboutHover,
      setHover: setAboutHover,
      items: ['Our Beliefs', 'Our Pastors', 'Our Services'],
    },
    // ...additional navbar items if any
  ];

  return (
    <Pane display="flex" flexDirection="column" background="white" width="100%">
      {/* Logo and social media icons */}
      <Pane display="flex" justifyContent="space-between" alignItems="center" paddingY={majorScale(1)}>
        <Link to="/">
          <img
              src={logobanner}
              alt="Kingdom Embassy Church Logo"
              style={{ height: 'auto', width: '50%', maxWidth: '100%', cursor: 'pointer' }}
          />
        </Link>
        {/* ...social icons */}
      </Pane>

      {/* Navigation Tabs */}
      <Pane display="flex" justifyContent="center" alignItems="center" padding={16}>
        {navbarItems.map((item) => (
          <Popover
            key={item.title}
            position={Position.BOTTOM_LEFT}
            content={
              <Menu>
                <Menu.Group>
                  {item.items.map((menuItem) => (
                    <Menu.Item key={menuItem} is={Link} to={`/${menuItem.toLowerCase().replace(/\s+/g, '-')}`}>
                      {menuItem}
                    </Menu.Item>
                  ))}
                </Menu.Group>
              </Menu>
            }
            onMouseEnter={handleMouseEnter(item.setHover)}
            onMouseLeave={handleMouseLeave(item.setHover)}
          >
            <Button marginRight={16} appearance="minimal" height={majorScale(5)}>
              <Heading size={600} color={item.hover ? 'orange' : 'black'}>
                {item.title}
              </Heading>
            </Button>
          </Popover>
        ))}
        {/* Direct Links for PRAYER and GIVE */}
        <Link to="/prayer">
          <Button marginRight={16} appearance="minimal" height={majorScale(5)}>
            <Heading size={600}>PRAYER</Heading>
          </Button>
        </Link>
        <Link to="/give">
          <Button marginRight={16} appearance="minimal" height={majorScale(5)}>
            <Heading size={600}>GIVE</Heading>
          </Button>
        </Link>
      </Pane>
    </Pane>
  );
}

export default Navbar;
