import React from 'react';
import Navbar from '../components/Navbar'; // Adjust the path according to your project structure
import ImageSwiper from '../components/Hero';
import Welcome from '../components/Welcome';
import JoinUs from '../components/JoinUs';
import Footer from '../components/Footer';
import UpcomingEvents from '../components/upcomingevents';

import { Pane, Heading, Card, Paragraph, Button, Icon, majorScale } from 'evergreen-ui';
import risingSunBanner from '../Assets/images/welcome.png'; // Ensure this path is correct
 
 
function ServiceInformation() {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding={majorScale(4)}
      position="relative"
      width="100%" // Ensure this Pane takes full width
    >
      {/* Background Image with a Translucent Overlay */}
      <Pane
        backgroundImage="url('/path-to-your-background-image.jpg')" // Replace with the actual path to your image
        backgroundSize="cover"
        backgroundPosition="center"
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        zIndex={-1}
      >
        <Pane
          background="rgba(0, 0, 0, 0.3)" // Translucent overlay for text readability
          width="100%"
          height="100%"
        />
      </Pane>

      {/* Content */}
      <Pane
        zIndex={1} // Ensure content is above the background image
        width="100%"
        maxWidth={1024} // Adjust the max width as needed
        padding={majorScale(4)}
        background="rgba(255, 255, 255, 0.9)" // Semi-transparent background for content readability
        borderRadius={3}
      >
        <Heading size={700} marginBottom={majorScale(2)} color="black">Join us every Sunday in-person or online at 10:00am!</Heading>
        <Paragraph size={500} color="black" marginBottom={majorScale(1)}>
          <Icon icon="home" marginRight={8} />8733 Frankford Ave, Philadelphia, PA. 19136
        </Paragraph>
        <Button appearance="primary" marginRight={16}>Get Directions</Button>
        {/* Include Bus Route Information if needed */}
      </Pane>
    </Pane>
  );
}

 

 
function WelcomeSection() {
  // Assuming you have a theme or style guide with colors and fonts
  const theme = {
    backgroundColor: '#002b48', // Dark blue background as seen in the image
    headingColor: 'white', // Text color for headings
    paragraphColor: 'lightgray', // Text color for paragraphs
    primaryButtonColor: '#FFA500', // Color for the 'Discover More' button
    // You can add more to your theme as needed
  };

  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding={majorScale(6)}
      background={theme.backgroundColor} // Use the dark blue background color
      borderRadius={3}
      elevation={1}
      width="100%"
    >
      {/* Logo or Image can be added here if needed */}
      
      {/* Main Heading */}
      <Heading size={900} color={theme.headingColor} marginBottom={majorScale(2)}>
        Welcome to CAC Kingdom Embassy!
      </Heading>

      {/* Subheadings / Hashtags */}
      <Paragraph size={500} color={theme.paragraphColor} marginBottom={majorScale(1)}>
        #EncounterGod #EquipPeople
      </Paragraph>

      {/* Brief Description */}
      <Paragraph size={400} color={theme.paragraphColor} marginBottom={majorScale(2)}>
        At Kingdom Embassy Church, We believe that Jesus Christ has invited all people into relationship with God, fellowship with each other, and mandated us to fulfill the Great Commission.
      </Paragraph>

      {/* Call-to-action Buttons */}
      <Pane>
        <Button appearance="primary" intent="success" backgroundColor={theme.primaryButtonColor} marginRight={16}>
          Join Zoom Meeting
        </Button>
        {/* <Button appearance="primary" intent="none">GIVE / TITHE</Button> */}
      </Pane>
    </Pane>
  );
}



 

function Home() {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding={majorScale(6)}
      background="tint1"
      width="100%" // Ensure the main container takes full width
    >
      {/* Welcome Section */}
        <WelcomeSection />
      {/* Service Information and Church Address */}

        <ServiceInformation />
 




    </Pane>
  );
}





function HomeView() {
    return (
        <div>
            <ImageSwiper />
            <Home />
            {/* <UpcomingEvents /> */}
            



        </div>
    );
}

export default HomeView;
