// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// https://firebase.google.com/docs/web/setup

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLKiku7hkidTKLG2lugSucU1ZVIqM5bt8",
  authDomain: "cackeadmin.firebaseapp.com",
  projectId: "cackeadmin",
  storageBucket: "cackeadmin.appspot.com",
  messagingSenderId: "993727387570",
  appId: "1:993727387570:web:0d44786b5cd442d1cc76ab",
  measurementId: "G-C4W3EEV6YE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

const analytics = getAnalytics(app);

export { firestore, getDocs, storage, auth };