import React, { useState } from 'react';
import { Pane,Paragraph, Heading, Card, Text, majorScale } from 'evergreen-ui';
 
const serviceTimes = [
  {
    title: "SUNDAY WORSHIP",
    location: "8733 Frankford Ave | Philadelphia, PA 19136",
    times: "Sundays | 10am ET",
    note: "*Join us in person or via Zoom"
  },
  {
    title: "BIBLE STUDY",
    location: "Zoom",
    times: "Thursday | 6:30pm ET",
    note: "*Join us on Zoom only"
  },
  {
    title: "PRAYER MEETING",
    location: "8733 Frankford Ave | Philadelphia, PA 19136",
    times: "Friday | 7pm ET",
    note: "*Join us in person only"
  },
  {
    title: "MORNING PRAYERS",
    location: "Prayer Hotline",
    times: "Monday & Wednesday | 6 - 6:30am ET",
    note: "xxxxxx"
  }
];
const theme = {
  backgroundColor: '#002b48', // Dark blue background as seen in the image
  headingColor: 'white', // Text color for headings
  paragraphColor: 'lightgray', // Text color for paragraphs
  primaryButtonColor: '#FFA500', // Color for the 'Discover More' button
  // You can add more to your theme as needed
};

const ServiceCard = ({ title, location, times, note }) => (
  <Card
    elevation={1}
    hoverElevation={2}
    width={350} // Increased width
    height={250} // Increased height
    margin={majorScale(2)}
    padding={majorScale(2)}
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    background="white"
    borderRadius={3}
  >
    <Heading size={600}>{title}</Heading>
    <Text size={500}>{location}</Text>
    <Text size={500}>{times}</Text>
    <Text size={300} color="muted">{note}</Text>
  </Card>
);



const AccordionItem = ({ title, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const accordionStyle = {
    cursor: 'pointer',
    borderBottom: '1px solid #E4E7EB',
    transition: 'background-color 250ms ease-in-out',
    padding: `${majorScale(2)}px 0`,
  };

  const accordionContentStyle = {
    maxHeight: isVisible ? '1000px' : '0',
    overflow: 'hidden',
    transition: 'max-height 500ms ease-in-out',
    padding: isVisible ? majorScale(2) : `0 ${majorScale(2)}px`,
  };

  return (
    <Pane>
      <Pane style={accordionStyle} onClick={() => setIsVisible(!isVisible)}>
        <Heading size={500} marginLeft={majorScale(2)}>
          {title}
          <span style={{ float: 'right', marginRight: majorScale(2) }}>
            {isVisible ? '−' : '+'} {/* These are Unicode characters for minus and plus */}
          </span>
        </Heading>
      </Pane>
      <Pane style={accordionContentStyle}>
        {isVisible && <Text>{children}</Text>}
      </Pane>
    </Pane>
  );
};

 

const OurServicesPage = () => (
  <Pane>
    {/* Hero Section */}
    <Pane
      backgroundImage="url('/path-to-hero-background.jpg')"
      backgroundSize="cover"
      backgroundPosition="center"
      height={360}
      display="flex"
      alignItems="center"
      justifyContent="center"
      background={theme.backgroundColor} // Use the dark blue background color

    >
      <Heading size={900} color="white">Our Services</Heading>
    </Pane>

    {/* Services Section */}
    <Pane display="flex" justifyContent="center" padding={majorScale(4)}>
      {serviceTimes.map((service, index) => (
        <ServiceCard key={index} {...service} />
      ))}
    </Pane>

    {/* FAQs Section */}
    {/* <Pane padding={majorScale(4)}>
      <Heading size={800} marginBottom={majorScale(2)}>Plan Your Visit</Heading>
       <AccordionItem title="Are your services livestreamed?">
        Yes, you can join us live on our website or our social media channels.
      </AccordionItem>
    </Pane> */}
  </Pane>
);


 
 
 

export default OurServicesPage;
