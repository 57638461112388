// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import './App.css';
// import Navbar from './components/Navbar'; // Make sure to import your Navbar component
// import HomeView from './views/HomeView'; // Home component
// import Footer from './components/Footer';
// import OurBeliefsPage from './components/OurBeliefs';
// import PastorProfile from './components/OurPastors';
// import AboutUsView from './views/AboutUsView'; // About component
// import OurServicesPage from './components/OurServices';
// import PrayerPage from './components/Prayer';
// import GivingPage from './components/Giving';
// // import PrayerView from './views/PrayerView'; // Prayer component
// // import GiveView from './views/GiveView'; // Give component
// // Import other views as needed

// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <Navbar />
//         <Routes>
//           <Route path="/" element={<HomeView />} />
//           <Route path="/about-us" element={<AboutUsView />} />
//           <Route path="/our-beliefs" element={<OurBeliefsPage />} />
//           <Route path="/our-pastors" element={<PastorProfile />} />
//           <Route path="/our-services" element={<OurServicesPage />} />
//           <Route path="/prayer" element={<PrayerPage />} />
//           <Route path="/give" element={<GivingPage />} />

//           {/* <Route path="/prayer-requests" element={<PrayerView />} />
//           <Route path="/give-online" element={<GiveView />} />  */}
//           {/* Define other routes as needed */}
//         </Routes>
//         <Footer />
//       </div>
//     </Router>
//   );
// }

// export default App;

// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar'; // Make sure to import your Navbar component
import HomeView from './views/HomeView'; // Home component
import Footer from './components/Footer';
import OurBeliefsPage from './components/OurBeliefs';
import PastorProfile from './components/OurPastors';
import AboutUsView from './views/AboutUsView'; // About component
import OurServicesPage from './components/OurServices';
import PrayerPage from './components/Prayer';
import GivingPage from './components/Giving';
import QRRedirect from './components/QR'; // Import the QRRedirect component

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/about-us" element={<AboutUsView />} />
          <Route path="/our-beliefs" element={<OurBeliefsPage />} />
          <Route path="/our-pastors" element={<PastorProfile />} />
          <Route path="/our-services" element={<OurServicesPage />} />
          <Route path="/prayer" element={<PrayerPage />} />
          <Route path="/give" element={<GivingPage />} />
          <Route path="/qr/:id" element={<QRRedirect />} /> {/* Dynamic route for QR codes */}
          {/* <Route path="/prayer-requests" element={<PrayerView />} />
          <Route path="/give-online" element={<GiveView />} />  */}
          {/* Define other routes as needed */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
