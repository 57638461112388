import React from 'react';
import { Pane, Text, majorScale } from 'evergreen-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

import logobanner from '../Assets/images/logo-banner-transp.png'; // Ensure the path is correct

function Footer() {
  return (
    <Pane
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      background="#333333" // Replace with the actual color from the design
      padding={majorScale(2)}
      borderTop="default"
      width="100%"
    >
      {/* Logo Section */}
      <Pane display="flex" alignItems="center">
        <img src={logobanner} alt="Church Logo" height="60" /> {/* Adjust size as needed */}
        <Text color="white" marginLeft={majorScale(2)}>CAC Kingdom Embassy</Text>
        <Text color="muted" marginLeft={majorScale(1)}>8733 Frankford Ave | Philadelphia, PA 19136</Text>
        <Text color="muted" marginLeft={majorScale(1)}>215-792-2343</Text>
      </Pane>

      {/* Social Media Icons */}
      <Pane display="flex" alignItems="center">
        <a href="https://www.facebook.com/cackingdomembassy" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebookF} color="white" size="lg" style={{ marginRight: '15px' }} />
        </a>
        <a href="https://www.instagram.com/_kingdomembassy/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} color="white" size="lg" style={{ marginRight: '15px' }} />
        </a>
        <a href="https://www.youtube.com/@cackingdomembassy7823" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} color="white" size="lg" />
        </a>
      </Pane>
    </Pane>
  );
}

export default Footer;
