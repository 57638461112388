// src/appservices/AppManager.js

import { firestore, auth } from './firebase';
import { 
  doc, 
  setDoc, 
  addDoc,
  getDoc, 
  updateDoc, 
  deleteDoc, 
  collection, 
  getDocs 
} from 'firebase/firestore/lite';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  sendPasswordResetEmail 
} from 'firebase/auth';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';

class FirestoreService {
  constructor(collectionName) {
    if (!collectionName) {
      throw new Error('Collection name must be provided');
    }
    this.collectionRef = collection(firestore, collectionName);
  }

  // Firestore methods
  async addDocument(data) {
    const docRef = await addDoc(this.collectionRef, data);
    return docRef.id;
  }

  async createOrUpdateDocument(id, data) {
    const docRef = doc(this.collectionRef, id);
    await setDoc(docRef, data);
    return docRef.id;
  }

  async getDocument(id) {
    const docRef = doc(this.collectionRef, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      throw new Error('Document not found');
    }
  }

  async updateDocument(id, data) {
    const docRef = doc(this.collectionRef, id);
    await updateDoc(docRef, data);
  }

  async deleteDocument(id) {
    const docRef = doc(this.collectionRef, id);
    await deleteDoc(docRef);
  }

  async getAllDocuments() {
    const querySnapshot = await getDocs(this.collectionRef);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }

  // Authentication methods
  async register(email, password) {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  }

  async login(email, password) {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  }

  async logout() {
    await signOut(auth);
  }

  async resetPassword(email) {
    await sendPasswordResetEmail(auth, email);
  }

  onAuthStateChanged(callback) {
    return auth.onAuthStateChanged(callback);
  }
}

export default FirestoreService;
