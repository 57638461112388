// src/components/QR.js
import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import FirestoreService from '../appservices/AppManager'; // Import FirestoreService

// Initialize Mixpanel
// mixpanel.init('YOUR_MIXPANEL_PROJECT_TOKEN');

const QRRedirect = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the QR code identifier from the URL
  const hasStoredVisit = useRef(false); // To ensure the function runs only once

  useEffect(() => {
    const firestoreService = new FirestoreService('qr'); // Initialize FirestoreService with collection name

    // Store the visit information in Firestore
    const storeVisitInFirestore = async () => {
      if (hasStoredVisit.current) return; // Prevent duplicate calls
      hasStoredVisit.current = true; // Mark as executed
      try {
        await firestoreService.addDocument({
          source: 'QR Code',
          id: id,
          timestamp: new Date().toISOString(),
        });
        console.log('Visit stored in Firestore successfully.');
      } catch (error) {
        console.error('Error storing visit in Firestore:', error);
      }
    };

    storeVisitInFirestore();

 

    // Redirect to the home page
    navigate('/');
  }, [navigate, id]);

  return null; // Or a loading spinner, if you prefer
};

export default QRRedirect;
