import React from 'react';
 import AboutUsPage from '../components/AboutUs';
 

function AboutView() {
    return (
        <div>
            <AboutUsPage />
         


        </div>
    );
}

export default AboutView;
