import React from 'react';
import { Pane, Heading, Paragraph, majorScale } from 'evergreen-ui';
import Navbar from './Navbar'; // Assuming Navbar is your navigation bar component

const HeroSection = ({ title, subtitle }) => (
  <Pane
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    height={400} // Adjust based on your design
    backgroundImage="url('/path-to-your-hero-background.jpg')" // Add your image path
    backgroundSize="cover"
    backgroundPosition="center"
  >
    <Heading size={900} color="white" marginBottom={majorScale(1)}>
      {title}
    </Heading>
    <Paragraph size={500} color="white">
      {subtitle}
    </Paragraph>
  </Pane>
);

const ContentSection = ({ children }) => (
  <Pane
    display="flex"
    justifyContent="center"
    paddingY={majorScale(4)}
  >
    <Pane width="100%" maxWidth={960}>
      {children}
    </Pane>
  </Pane>
);

const AboutUsPage = () => (
  <Pane>
    <HeroSection
      title="About Us"
      subtitle="We're so glad you're here!"
    />
    <ContentSection>
      <Heading size={800} marginBottom={majorScale(2)}>
        Welcome to Kingdom Embassy Online!
      </Heading>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        We are so happy that you are here! We invite you to watch one of our online services or plug into one of our ministries so you can connect with others. If you have a need today, we are here to serve you; so please let us know how we can help!
      </Paragraph>
      {/* Add more content sections as needed */}
    </ContentSection>
  </Pane>
);

export default AboutUsPage;
