import React from 'react';
import { Pane, Heading, Text, majorScale, Card } from 'evergreen-ui';
import prayerImage from '../Assets/images/prayer_bkgnd.png';

const PrayerPage = () => (
  <Pane>
    {/* Hero Section with Overlay */}
    <Pane
      position="relative"
      height={400} // Larger hero section
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundImage={`url(${prayerImage})`}
      backgroundSize="cover"
      backgroundPosition="center"
    >
      {/* Semi-transparent Overlay */}
      <Pane
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        backgroundColor="rgba(0, 0, 0, 0.4)" // Lighter overlay for a smoother look
      />
      
      {/* Text and Buttons */}
      <Pane zIndex={1} padding={majorScale(4)} textAlign="center"> {/* Aligned text center */}
        <Heading size={900} color="white" marginBottom={majorScale(3)}>PRAYER CENTER</Heading> {/* Larger bottom margin */}
        <Heading size={700} color="white">PRAYER HOTLINE: (978) 990-5473</Heading>
        <Heading size={500} color="white">ACCESS CODE: 2928813</Heading>
      </Pane>
    </Pane>

    {/* Prayer Meetings Section */}
    <Pane
      display="grid"
      gridTemplateColumns="repeat(auto-fit, minmax(400px, 1fr))" // Larger min-width for cards
      gap={majorScale(4)} // Increased gap for spacing
      justifyContent="center" // Center cards in the grid
      padding={majorScale(5)} // More padding for larger grid area
    >
      <Heading size={800} marginBottom={majorScale(3)} textAlign="center">JOIN US FOR PRAYER</Heading> {/* Bolder and larger heading */}
      
      {/* Prayer Meeting Card */}
      <Card
        elevation={2} // Elevated for depth
        hoverElevation={3} // Elevated more on hover for interaction feedback
        background="white"
        padding={majorScale(4)} // More padding for content
        display="flex"
        flexDirection="column"
        justifyContent="space-between" // Spacing out the content
        borderRadius={8} // Rounded corners for a smoother card appearance
      >
        <Heading size={700} marginBottom={majorScale(2)}>BREAKTHROUGH PRAYER</Heading> {/* Bolder heading */}
        <Text fontSize="18px" marginBottom={majorScale(2)} fontWeight={500}>8733 Frankford Ave | Philadelphia, PA 19136</Text> {/* Bolder text */}
        <Text fontSize="18px" marginBottom={majorScale(1)} fontWeight={500}>Friday | 7pm ET</Text> {/* Bolder text */}
        <Text size={500} color="muted">*Join us in person only</Text> {/* Larger text */}
      </Card>

      {/* Morning Prayers Card */}
      <Card
        elevation={2} // Same as above
        hoverElevation={3}
        background="white"
        padding={majorScale(4)}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        borderRadius={8}
      >
        <Heading size={700} marginBottom={majorScale(2)}>MORNING PRAYERS</Heading>
        <Text fontSize="18px" marginBottom={majorScale(2)} fontWeight={500}>Prayer Hotline</Text>
        <Text fontSize="18px" marginBottom={majorScale(1)} fontWeight={500}>Monday & Wednesday | 6 - 6:30am ET</Text>
        <Text size={500} color="muted">*Join via hotline</Text>
      </Card>
    </Pane>
  </Pane>
);

export default PrayerPage;
