import React from 'react';
import { Pane, Heading, Text, majorScale, Card } from 'evergreen-ui';

const HeroSection = () => {
  const heroBackgroundStyle = {
    backgroundColor: '#0077B5', // This sets the background color of the hero section
    height: '30vh', // Adjust the height as necessary
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  };

  return (
    <Pane style={heroBackgroundStyle}>
      <Heading size={900} color="white">Giving</Heading>
    </Pane>
  );
};

const GivingPage = () => {
  const givingMethodsGridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', // Creates a responsive grid layout
    gap: majorScale(3),
    padding: majorScale(4),
    justifyContent: 'center', // This centers the grid items
  };

  const centeredPaneStyle = {
    display: 'flex',
    justifyContent: 'center', // This centers the child container
    padding: majorScale(4),
  };

  return (
    <Pane>
      <HeroSection />
      
         <Pane style={givingMethodsGridStyle}>
        <Card
          elevation={1}
          hoverElevation={2}
          background="#00C853" // Green background for CashApp
          padding={majorScale(2)}
          margin={majorScale(2)}
          width={350}
          // Adjust based on your design
          textAlign="center"
        >
          <Heading size={600} marginBottom={majorScale(2)} color="white">CashApp</Heading>
          <Text color="white">Use CashApp with our handle: $KingdomEmbassyChurch</Text>
        </Card>

        <Card
          elevation={1}
          hoverElevation={2}
          background="#7C4DFF" // Purple background for Zelle
          padding={majorScale(2)}
          margin={majorScale(2)}
          width={350}
        // Adjust based on your design
          textAlign="center"
        >
          <Heading size={600} marginBottom={majorScale(2)} color="white">Zelle</Heading>
          <Text color="white">Use Zelle with our email: cackingdomembassy@gmail.com</Text>
        </Card>

        {/* Additional cards for Check, Bank Account etc. can be added here following the same structure */}
      </Pane>
     </Pane>
  );
};

export default GivingPage;
