import React from 'react';
import { Pane, Heading, Paragraph, IconButton, majorScale } from 'evergreen-ui';


import pastor_enoch from '../Assets/images/pastor_enoch.png';
const PastorProfile = () => {
  // Inline styles for layout
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: majorScale(4),
  };

  const imageStyle = {
    flex: 1,
    marginRight: majorScale(4),
  };

  const contentStyle = {
    flex: 2,
    maxWidth: '600px', // Adjust as needed for text readability
  };

  return (
    <Pane style={containerStyle}>
      <Pane style={imageStyle}>
        {/* Image would be set via CSS */}
        <img src={pastor_enoch} alt="Pastor Name" style={{ width: '100%', height: 'auto' }} />
      </Pane>
      <Pane style={contentStyle}>
        <Heading size={800}>Enoch Ilufoye | PASTOR</Heading>
        <Pane marginBottom={majorScale(2)}>
          {/* Social media icons */}
          {/* <IconButton icon={<TwitterIcon />} margin={8} />
          <IconButton icon={<FacebookIcon />} margin={8} /> */}
          {/* <IconButton icon={<EmailIcon />} margin={8} /> */}
        </Pane>
        <Paragraph>
        Enoch Ilufoye is the lead pastor of CAC Kingdom Embassy Church. Prior to this, Enoch started and led a youth’s church and actively served in other capacities at Christ Apostolic Church both locally and at the national body. 
Enoch is blessed to be married to Ann Ilufoye who also loves the lord and have served in different capacity in the church and other ministries. Enoch is an Engineer by training , while Ann is an Health Care Professional. They’ve been married since 2019 and have lived in South Philadelphia and New Jersey Area and are blessed with two wonderful children. 
        </Paragraph>
        {/* Continue with the rest of the bio */}
      </Pane>
    </Pane>
  );
};

export default PastorProfile;
