import React from 'react';
import { Pane, Heading, Paragraph, Icon } from 'evergreen-ui';

function JoinUs() {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding={40}
      margin={20}
      background="tint2"
      borderRadius={3}
      elevation={1}
    >
      <Heading size={700} marginBottom={16}>Join us every Sunday</Heading>
      <Paragraph size={500} marginBottom={8}>
        <Icon icon="time" marginRight={8} />10:00am In-person or Online
      </Paragraph>
      <Paragraph size={500} marginBottom={4}>
        <Icon icon="map-marker" marginRight={8} />
        {/* HARMONY EVENT CENTRE */}
      </Paragraph>
      <Paragraph size={400}>8733 Frankford Ave, Philadelphia, PA. 19136</Paragraph>
    </Pane>
  );
}

export default JoinUs;
