import React, { useEffect } from 'react';
 import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// Import local images
import image1 from '../Assets/images/biblestudy.png';
import image2 from '../Assets/images/kingdomparents.png';
import image3 from '../Assets/images/sundayservice.png';

function ImageSwiper() {
  // Array of local image imports
  const localImages = [image1, image2, image3];

  useEffect(() => {
    new Swiper('.swiper', {
      modules: [Navigation, Pagination, Autoplay],
      spaceBetween: 30,
      slidesPerView: 1,
      navigation: true,
      pagination: { clickable: true },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    });
  }, []);

  return (
    <div className="swiper">
      <div className="swiper-wrapper">
        {localImages.map((url, index) => (
          <div className="swiper-slide" key={index}>
            <img src={url} alt={`Slide ${index + 1}`} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
          </div>
        ))}
      </div>
      {/* Add Pagination */}
      <div className="swiper-pagination"></div>
      {/* Add Navigation */}
      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
    </div>
  );
}

export default ImageSwiper;